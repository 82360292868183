<template>
  <div class="background">
    <el-row class="content_row" :gutter="15"
    >
      <el-col
      :md="{span:4,offset:10}"
      style="text-align: center;margin-bottom: 20px;"
      >
      <el-button
      style="width: 100%;
      border-radius: 50px;
      "
      type="primary"
      @click.stop="createModelVisbile=true"
      >
        申请添加友情链接
      </el-button>
      </el-col>
      <el-col :md="{span:8,offset:8}" :xs="12">
        <InputSearchUi label="找人....."
         style="width: 100%;"
        @searchCond="searchCond"
        ></InputSearchUi>
      </el-col>
      <el-col :md="{span:5,offset:3}" :xs="12" style="text-align: right;">
        <el-select v-model="currentCheckType" style="width: 150px;">
          <el-option
          label="全部"
          value="all"
          ></el-option>
          <el-option
          label="只看友链"
          value="friendshipLink"
          ></el-option>
          <el-option
          label="只看本站用户"
          value="sysUser"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :md="24" style="margin-top: 30px;"
      v-infinite-scroll="load"
      infinite-scroll-disabled="disabled"
      infinite-scroll-immediate="false"
      :infinite-scroll-distance="500"
      >
        <el-col
        v-for="userListItem in userList"
        :md="{span:6}"
        :xs="{span:24,offset:0}"
        >
        <div v-for="userItem in userListItem.userListItem">
          <transition name="el-fade-in" :appear="true">
            <el-row>
              <el-col
              :xs="{span:24}"
              :md="{span:24}"
              >
               <userInfoCard
               v-if="currentCheckType=='all' || currentCheckType==userItem.userType"
               :user="userItem" style="margin-bottom: 15px;"></userInfoCard>
              </el-col>
            </el-row>
            </transition>
          </div>
        </el-col>
     </el-col>
    </el-row>
    <el-dialog title="添加友情链接" :visible.sync="createModelVisbile" width="400px"  center>
        <div>
          <h3 style="text-align: center;">请将本站也加入到您的友链中吧！</h3>
          <p>链接：http://qianyafan.top</p>
          <p>名称：城北徐公</p>
          <p>描述：一个多用户分享文章的平台，哈哈哈</p>
          <p>图标：http://qianyafan.top:8080/images/83d8ecbf850b49d79cb21dd9c4e9b794.png</p>
        </div>
        <el-input placeholder="名称" v-model="friendlinkForm.name" style="margin-bottom: 10px;"/>
        <el-input placeholder="网站链接" v-model="friendlinkForm.link" style="margin-bottom: 10px;"/>
        <el-input placeholder="图片链接" v-model="friendlinkForm.img" style="margin-bottom: 10px;"/>
        <el-input placeholder="介绍" v-model="friendlinkForm.description"
        type="textarea"
        style="margin-bottom: 10px;"/>
        <el-input placeholder="申请理由" v-model="friendlinkForm.reason"
        type="textarea"
        style="margin-bottom: 10px;"/>
        <div slot="footer" class="dialog-footer">
          <el-button @click="createModelVisbile = false">
            取消
          </el-button>
          <el-button type="primary"
          :loading="submitLoading"
          @click="createFriendLink">
            提交
          </el-button>
        </div>
    </el-dialog>
    <div
    style="height: 100px;float:left;width: 100%;">
        <span style="display: none;">{{disabled}}</span>
        <h4 v-if="noMore && !loading"style="color:#ffffff;text-align: center;">没有更多了哦！</h4>
        <div v-loading="loading" style="margin-top: 20px;">
        </div>
    </div>
    <div
    style="height: 550px;"
    v-if="loading || noMore"
    >
    </div>
  </div>
</template>

<script>
  import {getUserInfoByConditionApi,getUserApi} from '@/api/user'
  import InputSearchUi from '@/components/ui/InputSearchUi'
  import UserInfoCard from '../details/UserInfoCard.vue'
  import {createFriendshipApi} from '@/api/friendship_link'
  export default{
    name:'UserSearch',
    components: {
      InputSearchUi,
      UserInfoCard
    },
    created(){
      this.load()
    },
    data() {
      return {
        currentCheckType:'all',
        friendlinkForm:{},
        submitLoading:false,
        dataList:[],
        totalPage:undefined,
        currentPage:undefined,
        totalNumber:undefined,
        loading:false,
        createModelVisbile:false,
        userList:[
          {
            userListItem:[],
          },{
            userListItem:[],
          },{
            userListItem:[],
          },{
            userListItem:[],
          }
        ],
        query:{
          pageNum:1,
          pageSize:12
        },
      }
    },
    computed:{
      noMore() {
          return this.totalNumber < this.query.pageSize || this.totalPage===this.currentPage;
      },
      disabled() {
        return this.loading || this.noMore
      },
    },
  methods: {
    async createFriendLink(){
      this.submitLoading=true
      await createFriendshipApi(this.friendlinkForm).then((res)=>{
        if(res.data.success){
          this.$message({
            type:"success",
            message:"友链添加成功喽，请您也将本站添加上吧。"
          })
        }else{
          this.$message({
            type:"warning",
            message:res.data.errorMsg
          })
        }
        this.createModelVisbile=false
        this.submitLoading=false
      })
    },
    load() {
        this.loading=true
        this.getUserInfoByCondition()
    },
    restBlogList(){
      this.userList=[
        {
          userListItem:[],
        },{
          userListItem:[],
        },{
          userListItem:[],
        },{
          userListItem:[],
        }
      ]
    },
    searchCond(condition){
      this.restBlogList()
      this.query.pageNum=1
      this.query.pageSize=12
      this.query.condition=condition
      this.load()
    },
    async getUserInfoByCondition(){
     await getUserInfoByConditionApi(this.query).then((res)=>{
       if(res.data.success){
         const userListAll=res.data.data.list;
         this.totalNumber=res.data.data.list.length;
         let  i=0;
         for( i=0;i<userListAll.length;++i){
           this.userList[i%4].userListItem.push(userListAll[i]);
         }
         this.loading=false
         this.query.pageNum++
         this.totalPage=res.data.data.pages
         this.currentPage=res.data.data.pageNum
       }else{
         this.$message({
           type:'warning',
           message:res.data.errorMsg
         })
         this.loading=false
       }
     })

    },

    },
  }
</script>

<style scoped>
  .background{
    background: url(https://picx.zhimg.com/v2-491fbf4d75b42bf321734ee3b55ce888_r.jpg?source=1940ef5c) no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    padding-top: 100px;
  }
 .content_row{
 }
</style>
